.input_container {
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
  padding: 12px 18px;
  margin: 0.3rem auto;
  width: 80%;
}

.button_container {
  margin: 0.5rem auto;
  width: calc(80% + 36px);
  display: flex;
  justify-content: flex-end;
}

.added_years_container {
  background: var(--very-light-grey);
  position: relative;
}

.added_years_button {
  display: block;
  border: 2px solid black;
  color: rgba(0, 0, 0, 1);
  border-radius: 6px;
  background-color: white;
  font-size: 1.25rem;
  font-weight: 600;
  height: fit-content;
  position: absolute;
  top: 12px;
  right: 18px; /* needs to be same as `padding` for input_container as defined in Inputs.module.css */
}

.added_years_button:hover {
  cursor: pointer;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  transform: scale(1.2)
}
