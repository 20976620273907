.container {
  margin-top: 1rem;
}

.comp_table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  padding: 0;
  overflow: hidden;
  border-radius: 6px;
  border: none;
  border-radius: 10px;
}

.comp_table thead,
.tabcomp_tablele tbody,
.comp_table td {
  border: none;
  text-align: center;
}

/* .comp_table td:nth-of-type(3n + 0) {
  border-right: 1px solid black;
} */

.comp_table thead {
  /* border: 1px solid black;
  border-radius: 6px; */
  background-color: var(--main-color);
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.comp_table tbody tr:nth-child(even) {
  background-color: rgba(168, 168, 168, 0.2);
}

/* 
.comp_table tr:nth-child(odd) {
  padding: 1rem;
  background: #f2f2f2;
  border: 2px solid white;
  border-radius: 6px;
} */
