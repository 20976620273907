.pagebutton {
  font: inherit;
  cursor: pointer;
  background-color: var(--main-color);
  color: rgb(255, 255, 255);
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.25rem;
}

.pagebutton:hover,
.pagebutton:active {
  background-color: var(--main-color-light);
  border-color: var(--main-color-light);
}
