.background {
  position: fixed;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.75);
  width: 800vw;
  height: 1000vh;
  top: 0;
  left: 0;
}

.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  padding: 1.2rem;
  text-align: center;
  width: 50rem;
  position: fixed;
  z-index: 10;
  top: 15vh;
  left: calc(50% - 25rem);
}

.modal p:last-of-type {
  margin-bottom: 3rem
}

.btn_main {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  margin: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 1.25rem;
}

.btn_agreed {
  cursor: pointer;
  background-color: var(--main-color);
  color: rgb(255, 255, 255);
  border: 1px solid var(--main-color);
}

.btn_agreed:hover {
  background-color: var(--main-color-light);
  border-color: var(--main-color-light);
}

.btn_reject {
  background-color: rgb(175, 177, 182);
  color: rgb(109, 113, 120);
  border: 1px solid rgb(175, 177, 182);
}

.checkbox {
  accent-color: var(--main-color);
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
  margin: 0;
}

.checkbox:hover {
  cursor: pointer;
  background-color: var(--main-color-light);
}

.modal h2 {
  margin: 0.5rem;
}

.modal p,
.modal table {
  text-align: justify;
  margin: 0.75rem 0.75rem;
}

.modal table {
  margin: auto;
}

.modal label:hover {
  cursor: pointer;
}

.password {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 0px;
  align-items: center

}

.password input {
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-left: 10px
}

.modal .password p {
  margin: 0
}