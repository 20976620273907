.input_container {
  background-color: var(--very-light-grey);
  border-radius: 6px;
  padding: 12px 18px;
  margin: 0.3rem auto;
  width: 80%;
  transition: min-height 0.3s linear;
}

.input_container .safe {
  min-height: 390px;
}

.input_container .danger {
  min-height: 500px;
}

.input_container section {
  width: 100%;
  margin: 5px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input_container p {
  margin: 0;
}

.input_container select,
.input_container input {
  height: 1.75rem;
  width: 30%;
  font-size: 1.25rem;
  margin-top: 0.5rem;
}

.input_container button {
  display: block;
  border: 2px solid black;
  color: rgba(0, 0, 0, 1);
  border-radius: 6px;
  background-color: white;
  font-size: 1.25rem;
  font-weight: 600;
  height: fit-content;
}

.input_container button:hover {
  cursor: pointer;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  transform: scale(1.2)
}

.ErrorMsg {
  /* background-color: rgb(255, 0, 0, 0.2); */
  padding-top: 0.5rem;
  color: rgb(255, 0, 0);
  /* border-radius: 6px;
  padding: 12px 18px;
  margin: 0.3rem auto;
  width: 60%; */
}

.input_container .safe {
  min-height: 390px;
}

.input_container .danger {
  min-height: 500px;
}

.added_years_input_container {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
  transition: margin-bottom 0.3s linear;
}

.added_years_input_container input {
  margin-left: 10px;
  height: 1.75rem;
  width: 30%;
  font-size: 1.25rem;
}

.added_years_input_container:first-child {
  margin-left: 50px;
  z-index: 1;
}

.added_years_input_container:last-child {
  margin-left: -150px
}

.added_years_input_container .ErrorMsg {
  position: absolute;
  top: 75%;
  width: 88%;
  left: 12%;
  background: var(--very-light-grey);
}

.added_years_input_container.extra_space {
  margin-bottom: 50px;
}

.added_years_input_container.extra_space + * {
  margin-bottom: 50px
}