/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("./assets/fonts/open-sans-v34-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/open-sans-v34-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* * {
  outline: 1px solid black;
} */

html {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  /* line-height: 1.5; */
}

#root {
  --main-color : rgb(0,94,184);
  --main-color-light: rgb(65,182,230);
  --gad-teal : rgb(0,48,125);
  --light-grey: rgb(118,134,146);
  --full-legacy : rgba(0, 169,206);
  --full-legacy-light : rgba(0,169,206,0.5);
  --legacy-curtailed : rgb(66,85,99);
  --legacy-curtailed-light : rgba(66,85,99, 0.5);
  --CARE : rgb(65,182,230);
  --CARE-light : rgb(65,182,230);
  --CARE-super-light : rgba(0,94,184,0.15);
  --very-light-grey: rgb(232, 237,238)
}


body {
  padding: 0;
  border: 0;
  margin: 11.5rem auto 2.5rem auto;
  align-self: center;
  width: 98%;
  min-width: 900px;
  max-width: 1600px;
  --main-color: rgb(0,94,184);
  --main-color-light: rgb(65,182,230);
  --gad-teal: rgb(0,48,135);
  --light-grey: rgb(118,134,146);
  --full-legacy: rgb(0, 169,206);
  --full-legacy-light: rgb(128,212,231);
  --legacy-curtailed: rgb(66,85,99);
  --legacy-curtailed-light: rgb(160,170,177);
  --CARE: rgb(65,182,230);
  --CARE-light: rgb(65,182,230);
  --CARE-super-light: rgba(0,94,184,0.15);
  --main-color-super-light: rgb(192, 204, 223);
  --very-light-grey: rgb(232, 237,238)
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: Arial, sans-serif;
  /* Default weight and style as per your specifications: */
  font-weight: normal;
  font-style: normal;
}

/* To avoid using italics online */
* {
    font-style: normal !important;
}


h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 2rem;
}

p,
button, input, label, li {
  font-size: 1.25rem;
}

a {
  font-size: 1.25rem
}

h1,
h2,
h3,
h4,
p,
ul {
  margin: 0 0 0.75rem 0;
}

p:last-child,
p:only-child {
  margin: 0;
}

table,
th,
td {
  border-collapse: collapse;
  margin: 0 0 0.75rem 0;
  font-size: 1.25rem;
  border: 1px solid;
  padding: 0.5rem;
}

a {
  color: var(--main-color);
}
