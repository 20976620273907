.slider_wrapper_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2em;
    font-size: 2em;
    margin-top: 0px;
    user-select: none;
}

.sliderWrapper {
    --slider-height: .375em;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 3.125em;
    padding: 0.25em;
    width: 300px;
    background-image: linear-gradient(to bottom, #cdccd7, #efeef2);
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
    margin-top: 20px;
  }
  
  .sliderTrack {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: inherit;
    height: var(--slider-height);
    width: calc(100%);
    background-image: linear-gradient(to bottom, #fff, transparent), linear-gradient(to right, var(--slider-color) 0% calc((var(--slider-value) - 20) * 1.25%), #cccbd2 calc((var(--slider-value) - 20) * 1.25%) 100%);
    background-blend-mode: overlay, normal;
    box-shadow: inset 0 0.0625em 0.125em rgba(0, 0, 0, 0.4);
    
  }
  
  .sliderInput {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    border-radius: inherit;
    width: calc(100% + .875em);
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .sliderInput::-webkit-slider-thumb {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    padding: 0.5em;
    width: 1.25em;
    height: 1.25em;
    cursor: pointer;
  }
  .sliderInput::-moz-range-thumb {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    padding: 0.5em;
    width: 1.25em;
    height: 1.25em;
    cursor: pointer;
  }
  
  .sliderThumb {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc((var(--slider-value) - 20) / 80 * (100% - var(--slider-height)) + var(--slider-height) / 2);
    transform: translateX(-50%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-image: linear-gradient(to bottom, #f0f0f2, #c5c5cf);
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.slider-thumb::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  width: 68%;
  height: 68%;
  background-image: linear-gradient(to bottom, #c5c5cf, #f0f0f2);
}
  
.safe_perc_warning {
    display: flex;
    width: 150px;
    height: 75px; /* This is calculated based on geometry: 0.5 * width of pentagon */
    background-color: var(--CARE); /* To inherit the color of the span text */
    position: absolute;
    vertical-align: top;
    border-top-right-radius: 3px;
    color: white;
    align-items: center;
    justify-content: center;
    text-align: center;
    top : 80px;
    font-size : 16px;
    }
    
.safe_perc_warning::before{
    content: '';
    position: absolute;
    width: 70px;
    height: 50px;  /* 0.7071 (sqrt(2)/2) times the width of the pentagon */
    background-color: var(--CARE); /* Inherits the color from the span */
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    transform-origin: 50% 100%;
    border-bottom-right-radius: 3px;
}

.safe_perc_warning::before {
    top: 0;
    left: 50%;
    transform: translateY(-99%) translateX(-50%);
}

@keyframes zoom {
    0% {transform: translateX(-1000px)}
    25% {transform: translateX(-800px)}
    50% {transform: translateX(-500px)}
    75% {transform: translateX(-0px)}
    82% {transform: translateX(50px)}
    85% {transform: translateX(100px)}
    87% {transform: translateX(70px)}
    90% {transform: translateX(0px)}
    95% {transform: translateX(30px)}
    100% {transform: translateX(0px)}
}

.safe_percentage_lower_warning {
    margin-top: 20px;
    border: 5px solid red;
    background: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: zoom 0.3s ease-in-out;
    animation-fill-mode: forwards;
    margin-bottom: 20px
}

.safe_percentage_lower_warning p {
    font-size: 25px;
    font-weight: 900;
}

.safe_percentage_lower_warning button {
    margin-top : 10px
}

.manual_input {
    height: 40px;
    border-radius: 10px;
    border: 5px solid var(--slider-color);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500
}

.input_container {
    background-color: var(--very-light-grey);
    border-radius: 6px;
    padding: 12px 18px;
    margin: 0.3rem auto;
    width: 80%;
    transition: max-height 0.3s linear;
  }
  
  .input_container.safe {
    max-height: 300px;
  }
  
  .input_container.danger {
    max-height: 483px;
  }
  
  .input_container section {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 80px;
  }
  
  .input_container p {
    margin: 0;
  }

  .input_container button {
    display: block;
    border: 2px solid rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: transparent;
    font-size: 1.25rem;
    font-weight: 600;
    height: fit-content;
    cursor: pointer;
    background: white;
    transform: translateX(5px)
  }

  .input_container button:hover {
    transform: scale(1.15);
    border-color: var(--main-color);
    color: var(--main-color)
  }

  .what_mean:hover {
    cursor: pointer;
    background: white;
    transform: scale(1.2)
  }

  .what_mean {
    transition: transform 0.3s linear
  }