.container {

    flex-grow: 1;
    width: 80%;
    margin-left: 10%; 
  }
  
  .container_no_animation {
    flex-grow: 1;
    width: 80%;
    margin-left: 10%; 
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.cell {
    background: linear-gradient(to left, var(--col), var(--lighter));
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
    border-radius: 10px;
    height: 250px;
    width: 250px;
}

.cell.overwrite {
  margin-right:0
}

.cell:hover {
    
    filter: brightness(110%);
    cursor: pointer;
}

.image {

    transition: opacity 0.3s ease-in-out;
}

.cell * {
  transition: all 0.15s linear
}

.cell:hover * {
    opacity: 1;
    transform: scale(1.05)
}

.text {
    margin-top: 10px;
    text-align: center;
}

.help_button {
    position: absolute;
    top: 5%;
    right: 5%;
    display: block;
    border: 2px solid rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
    border-radius: 6px;
    background-color: white;
    font-size: 0.8rem;
    font-weight: 600;
    height: fit-content;
  }
  
  .help_button:hover {
    cursor: pointer;
    border: 2px solid var(--main-color);
    color: var(--main-color);
  }

  .box_title {
    align-self: flex-start;
    margin-left: 10%;
  }

  .grid > h2 {
    text-align: center;
    width: 280px;
  }


  .container .grid .cell {
    transform: translateY(2000px);
    opacity: 0;
    
  }

  .container .grid .cell:nth-child(3) {
    animation: fly_in 0.2s cubic-bezier(0.63, 0.05, 0.79, 1.35);
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  .container .grid .cell:nth-child(4) {
    animation: fly_in 0.2s cubic-bezier(0.63, 0.05, 0.79, 1.35);
    animation-delay: 1.15s;
    animation-fill-mode: forwards;
  }

  .container .grid .cell:nth-child(5) {
    animation: fly_in2 0.2s cubic-bezier(0.63, 0.05, 0.79, 1.35);
    animation-delay: 1.3s;
    animation-fill-mode: forwards;
  }

  .container .grid .cell:nth-child(6) {
    animation: fly_in2 0.2s cubic-bezier(0.63, 0.05, 0.79, 1.35);
    animation-delay: 1.45s;
    animation-fill-mode: forwards;
  }

  .container .grid .cell h1, .container .grid .cell h2, .container .grid .cell h3, .container .grid .cell p {
    animation: fade_in 0.2s ease-in;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-delay: 1.85s;
  }

  @keyframes fade_in {
    0% {opacity: 0}
    100% {opacity: 1}
  }

  @keyframes fly_in {
    0% {transform: scale(0) translateY(2000px) translateX(0%); opacity: 0}
    2% {transform: scale(0) translateY(2000px) translateX(0%); opacity: 1}
    100% {transform: scale(1) translateY(0%) translateX(0%); opacity: 1}
  }

  @keyframes fly_in2 {
    0% {transform: scale(0) translateX(2000px) translateY(0%); opacity: 0}
    2% {transform: scale(0) translateX(2000px) translateY(0%); opacity: 1}
    100% {transform: scale(1) translateX(0%) translateY(0%); opacity: 1}
  }

  