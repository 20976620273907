.service_container {
  background-color: var(--very-light-grey);
  border-radius: 6px;
  padding: 17px 23px;
  margin: 0.3rem auto;
  width: calc(80% - 10px);
}

.service_container section {
  width: 100%;
  margin: 5px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.service_container select,
.service_container input {
  height: 1.75rem;
  font-size: 1.25rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  width: 50%;
}

.subContainer button, .service_container table button {
  font: inherit;
  cursor: pointer;
  background-color: var(--main-color);
  color: rgb(255, 255, 255);
  padding: 0.5rem 1rem;
  border: 1px solid var(--main-color);
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.25rem;
  height: 2.3rem;
}

.service_container section button {
  display: block;
  border: 2px solid black;
  color: rgba(0, 0, 0, 1);
  border-radius: 6px;
  background-color: white;
  font-size: 1.25rem;
  font-weight: 600;
  height: fit-content;
}

.service_container section button:hover {
  cursor: pointer;
  border: 2px solid var(--main-color);
  color: var(--main-color);
  transform: scale(1.2)
}

.subContainer button:hover, .service_container table button:hover {
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.service_container table {
  background-color: white;
  table-layout: fixed;
  width: 75%;
  text-align: center;
  border: none;
  margin: 1rem;
}

.service_container th,
.service_container td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 1rem;
  width: 50%;
}

.leftColumn {
  background-color: var(--very-light-grey);
  border-top-color: var(--very-light-grey);
  border-left-color: var(--very-light-grey);
  border-bottom-color: var(--very-light-grey);
  text-align: right;
  width: 1rem;
}

.rightColumn {
  background-color: var(--very-light-grey);
  border-top-color: var(--very-light-grey);
  border-right-color: var(--very-light-grey);
  border-bottom-color: var(--very-light-grey);
  text-align: left;
  width: 1rem;
}

.subContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.label_input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
