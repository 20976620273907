.text_and_chart_container {
    display: flex;
    width: 100%;
    height: 85%;
    margin-bottom: 5%;
}

.chart_container {
    width: 100%;
    height: 50%;
}

.text_container {
    height: 100%;
    width: 100%;
}

.modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    padding: 0.6rem;
    text-align: center;
    width: 90%;
    position: fixed;
    z-index: 10;
    top: 5%;
    height: 90%;
    left: 5%;
}

.single_explanation {
    padding: 30px;
    user-select : none;
    margin-bottom: 15px;
    transition: max-height 0.3s linear;
    overflow: hidden;
    cursor: pointer;
}

.single_explanation:hover {
    transform : scale(1.01);
    border: 2px solid black
}

.single_explanation p {
    text-align: left
}

.single_explanation.dot {
    border-radius: 20px;
}

.single_explanation:last-child {
    color: white;
}

.general_explanation {
    margin-top: 40px;
    min-height: 40%;
    border: 2px solid black;
    min-width: 80%;
    margin-left: 10%;
    background: rgb(217,242,240);
    font-size: 14px;
    border-radius: 10px;
    transition: all 0.3s linear;
    cursor: pointer;
    width: 80%;
    padding: 20px;
    overflow: scroll;
}


.general_explanation {
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable vertical scrolling */
    max-height: 150px; /* Set a maximum height */
    transition: max-height 0.3s ease; /* Smooth transition for expansion */
  }
  
  /* Add styles for expanded state */
  .general_explanation.general_explanation_variables.general_explanation_extra {
    max-height: 600px; /* Increased height when expanded */
  }
  
  /* Custom scrollbar styles */
  .general_explanation::-webkit-scrollbar {
    width: 12px; /* Scrollbar width */
  }
  
  .general_explanation::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 6px; /* Rounded corners */
  }
  
  .general_explanation::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Thumb color on hover */
  }
  
  /* Firefox custom scrollbar */
  .general_explanation {
    scrollbar-color: #888 #e0e0e0; /* Thumb and track color */
    scrollbar-width: thick; /* Scrollbar width */
  }






  

.general_explanation p {
    text-align: left;
}

.general_explanation:hover {
    transform: scale(1.02);
    box-shadow: 5px 5px 5px 5px lightgrey

}

.general_explanation_variables {
    box-shadow: 2px 2px 2px 2px lightgrey;
}

.general_explanation.general_explanation_variables {
    min-width: 180%;
    min-height: 60%;
    color: black;

}


.general_explanation.general_explanation_variables.general_explanation_extra {
    max-height: 55%;
    min-height: 55%;
    min-width: 100%;
    transform: translateY(-10%)
}

.text_subcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(80% + 2 * 20px); /* 3 times the box width + 2 times the gap between the boxes */
    
}

.single_explanation_neat {
width: 28%; /* or whatever width you want */
height: 180px; /* or whatever height you want */
margin-bottom: 20px; /* gap between rows */
background-color: #eee; /* just for visualization */
border-radius: 10px;
display: flex;
justify-content: center;
align-items: cente;
flex-direction: column;
padding: 10px;

transition: transform 0.3s linear;
}

/* For the last row with 2 boxes */
.text_subcontainer > div:nth-last-child(-n+2) {
margin-left: auto;
margin-right: auto;
}


.remove_margin .single_explanation_neat {
    margin-bottom : 0
}

.text_label_container {
    display: flex; 
    align-items: center;
    justify-content: center;
}

.single_explanation_neat:hover {
    transform: scale(1.03);
    box-shadow: 5px 5px 5px 5px lightgray
}

.full_explanation_div {
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid black;
    transform: translateY(-150px);
}

.full_explanation_div_income {
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid black;
}

.full_explanation_div_income p {
    font-size: 18px
}

.row_div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

.row_div div {
    width: 33.3333%;
    box-sizing: border-box;
    margin: 5px;
    border-radius: 10px;
}

.row_div .single_explanation p {
    text-align: center
}

.row_div .single_explanation:hover {
    transform : scale(1.03);
    border: 0px solid black
}


.general_explanation.general_explanation_income {
    padding: 20px;
    border-radius: 10px;
    background: var(--CARE-super-light);
    transition: all 0.1s ease-in
}

.general_explanation.general_explanation_income:hover {
    transform: scale(1.03);
    box-shadow: 5px 5px 5px 5px lightgrey;
    cursor: pointer;
}


.subcontainer_variable_neat {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.subcontainer_variable_neat div:hover {
    cursor: pointer;
}

.close_button_income {
    transition: transform 0.3s linear;
    color : black;
    border : 2px solid black;
    background : white
}

.close_button_income:hover {
    background: var(--main-color);
    box-shadow: 1px 1px 1px 1px lightgrey;
    transform: scale(1.03);
    color: white;
    border: none;
    cursor: pointer;
}


  
  
.text_subcontainer > div.subcontainer_variable_neat:nth-of-type(3) .single_explanation_neat.shrink {
    transform: translateY(-210px) translateX(var(--guide)) scale(0.6)
}

.text_subcontainer > div.subcontainer_variable_neat:nth-of-type(2) .single_explanation_neat.shrink {
    transform: translateY(-120px) translateX(var(--guide)) scale(0.6)
}

.text_subcontainer > div.subcontainer_variable_neat:nth-of-type(1) .single_explanation_neat.shrink {
    transform: translateY(-30px) translateX(var(--guide)) scale(0.6)
}

.text_subcontainer > div.subcontainer_variable_neat:nth-of-type(3) .single_explanation_neat.shift_position {
    transform: translateY(-170px) translateX(calc(-600px + 2.4 * var(--guide))) scale(1.2);
    z-index: 1000;
}

.text_subcontainer > div.subcontainer_variable_neat:nth-of-type(2) .single_explanation_neat.shift_position {
    transform: translateY(-50px) translateX(calc(-600px + 2.4 * var(--guide))) scale(1.2);
    z-index: 1000;
}

.text_subcontainer > div.subcontainer_variable_neat:nth-of-type(1) .single_explanation_neat.shift_position {
    transform: translateY(40px) translateX(calc(-600px + 2.4 * var(--guide))) scale(1.2);
    z-index: 1000;
}